@media screen and (max-width: 576px) {
  .bundle {
    flex-direction: column !important;
  }
  .payment-checkout-button {
    min-width: 180px !important;
  }
}

.bundles {
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 512px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.bundle {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(245, 245, 245, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-height: calc(65px - 2px);
  text-align: left;
  margin-bottom: -1px;
  padding-left: 14px;
  padding-right: 14px;
}

.bundle-purchase-amount-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bundle-purchase-amount {
  display: inline-block;
  font-weight: bold;
  font-size: 2rem;
  margin: 0 10px 2px 10px;
}

.bundle-purchase-amount-input-wrapper {
  min-height: calc(65px - 2px);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bundle-purchase-amount-input {
  min-height: 30px;
  max-width: 120px;
  padding: 0 10px;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  border: 2px solid rgba(0, 0, 0, 0.5);
  outline: none;
}

.bundle-purchase-button {
  color: rgba(255, 255, 255, 1);
  height: 42px;
  min-width: 120px;
  padding: 8px 30px;
  font-size: 16px;
  border-radius: 30px;
  border: 1px solid transparent;
  cursor: pointer;
  text-align: center;
  outline: none;
  text-decoration: none !important;
}

.bundle-purchase-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.go-back-button {
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  background: transparent;
  color: rgba(51, 51, 51, 0.5);
}

.go-back-button:hover {
  color: rgba(51, 51, 51, 1);
}

.payment-checkout-button {
  background: rgba(20, 20, 20, 1);
  background: linear-gradient(145deg, rgba(20, 20, 20, 1) 0%, rgba(120, 120, 120, 1) 65%, rgba(225, 225, 225, 1) 80%);
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  max-height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-checkout-button span {
  width: calc(100% - 100px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.5rem;
  font-weight: bold;
}

.payment-checkout-button:disabled {
  background: rgba(225, 225, 225, 1);
  cursor: not-allowed;
}

.payment-checkout-button-payment-logo {
  width: 100px;
  height: 50px;
}
